/* eslint-disable */
import React from 'react'
import Link from 'gatsby-link'
import Img from "gatsby-image";
import {DebounceInput} from 'react-debounce-input';
import Layout from '../components/layout'
import { graphql } from 'gatsby'

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import CenteredTitleWithSubheader from '../paragraphs/centered_title_with_subheader.js';
import ImageLeftTextRightGray from '../paragraphs/image_left_text_right_gray.js';
import GeneralFooter from '../components/generalFooter';

import rightarrow from "./../layouts/images/right-arrow.svg"

import sendEvent from '../helpers/sendAnalytics';

class BlogsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = {
      search: '',
      blogs: this.props.data.allNodeBlog.edges
    };
  }

  componentDidMount(){
      if(window.location.hash == '#community'){
          this.handleSearchChange({},'Community')
      }
  }

  handleSearchChange(e,val) {
      if(val == 'Inspiration' || val == 'Trends' || val == 'Rooms' || val == 'DIY' || val == 'Seasonal' || val == 'Community'){
        sendEvent({
         ga: {
           category: 'Bring_Comfort_Home',
           action: 'Theme_Filter',
           label: val
         }
       })
      } else {
        if(!this.state.eventSent){
          sendEvent({
           ga: {
             category: 'Bring_Comfort_Home',
             action: 'Theme_Filter',
             label: 'Search_Function'
           }
          })
          this.setState({eventSent: true})
        }
      }
    if (typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    var search = '';
    if (val) {
      search = val;
      this.setState({search: val});
    } else {
      search = e.target.value;
      this.setState({search: e.target.value});
    }

    if (search === '') {
      this.setState({blogs: this.props.data.allNodeBlog.edges});
      return;
    }

    let filtered = [];
    this.props.data.allNodeBlog.edges.forEach((item) => {
      if (item.node.relationships.field_tags === null) return;
      item.node.relationships.field_tags.forEach((tag) => {
        if (tag.name.toLowerCase().indexOf(search.toLowerCase()) != -1) {
          filtered.push(item);
        }
      })
    });

    this.setState({blogs: filtered});
  }
  render() {
    const data = this.props.data.nodePage
    const featured = this.props.data.nodeBlog
    const retailers = this.props.data.allNodeStore.edges
    let featuredText = ''
    if(featured){
        if (featured.relationships.field_paragraph[0].field_body_text) {
          featuredText = featured.relationships.field_paragraph[0].field_body_text.value.replace('<p>','').replace('</p>','').slice(0,100).concat('...')
        } else if (featured.relationships.field_paragraph[1].field_body_text) {
          featuredText = featured.relationships.field_paragraph[1].field_body_text.value.replace('<p>','').replace('</p>','').slice(0,100).concat('...')
        }
    }
    return (
      <Layout>
        <div className="page-wrapper bringComfortHome">
          <SEO meta={data.field_meta_tags} />
          <Header retailers={retailers}></Header>
          <div style={{clear:'both'}} />

          <div className="content-wrapper">
            {data.relationships.field_paragraph.map(paragraph => {
              switch(paragraph.__typename) {
                case 'paragraph__centered_title_with_subheader':
                  return (
                    <CenteredTitleWithSubheader key={paragraph.id} data={paragraph} />
                  );
                  break;
              }
            })}

            { featured ?
                <div className="image-left-and-text-right blog-image-left-text-right">
                  <div className="image-left-and-text-right blog-image-left-text-right_wrapper">
                    <div className="image-left-text-right-image no-bg">
                    {(featured.relationships.field_large_image && featured.relationships.field_large_image.localFile)
                      ? <img src={featured.relationships.field_large_image.localFile.childImageSharp.sizes.src} />
                      : null }
                    </div>
                    <div className="image-left-text-right-text blog-image-left-text-right-text">
                      <div className="intro-bold-sub-title intro-bold blue-text">Featured</div>
                      <div className="heading">{featured.title}</div>
                      { (featured.relationships.field_paragraph[1].field_body_text) ?
                        <div className="section-body-text no-italics" dangerouslySetInnerHTML={{__html: featured.relationships.field_paragraph[1].field_body_text.value.replace('<p>','').replace('</p>','').slice(0,72).concat('...') }}></div>
                        :
                        ''
                      }
                      <div className="arrow-link">
                        <img src={rightarrow} className="image" />
                        <Link
                          to={featured.path.alias}
                          key={featured.id}
                          className="blue-arrow-link">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
            :null
            }

            <div className="search-grid-block">
              <div className="search-grid-block-grid">
                <div className="search-grid-block-bar">
                  <div className="search-grid-block-bar-wrapper">
                    <div className="search-grid-block-input">
                      <form className="search w-form">
                        <DebounceInput debounceTimeout={700} minLength={2} type="text" className="search-input section-body-text w-input" maxLength="256" name="query" placeholder="Search Posts" id="search" required="" value={this.state.search} onChange={this.handleSearchChange} />
                        <input type="submit" value=" " className="search-button w-button" />
                      </form>
                    </div>
                    <div className="search-popular-list">
                      <div className="search-popular-list-title">Popular Searches</div>
                      <div className="search-popular-list-items">
                        <ul className="search-list w-list-unstyled">
                          <li className="search-list-item"><a href="#" className="search-list-link" onClick={(e) => this.handleSearchChange(e,'Inspiration')}>Inspiration</a></li>
                          <li className="search-list-item"><a href="#" className="search-list-link" onClick={(e) => this.handleSearchChange(e,'Trends')}>Trends</a></li>
                          <li className="search-list-item"><a href="#" className="search-list-link" onClick={(e) => this.handleSearchChange(e,'Rooms')}>Rooms</a></li>
                          <li className="search-list-item"><a href="#" className="search-list-link" onClick={(e) => this.handleSearchChange(e,'DIY')}>DIY</a></li>
                          <li className="search-list-item"><a href="#" className="search-list-link" onClick={(e) => this.handleSearchChange(e,'Seasonal')}>Seasonal</a></li>
                          <li className="search-list-item"><a href="#" className="search-list-link" onClick={(e) => this.handleSearchChange(e,'Community')}>Community</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-grid-block-items w-clearfix">
                  {this.state.blogs.map(blog => {
                    return (
                      <Link
                        to={blog.node.path.alias}
                        key={blog.node.id}
                        className="column-item blogs-column-item"
                      >
                        <div className="column-item-wrapper blog-column-item-wrapper">
                          <div className="column-item-image">
                            {(blog.node.relationships.field_image && blog.node.relationships.field_image.localFile)
                              ? <img src={blog.node.relationships.field_image.localFile.childImageSharp.sizes.src} />
                              : null }
                          </div>
                          <div className="colum-item-text blog-column-text">
                            <div className="text-link-title no-border">{blog.node.title}</div>
                            <div className="text-link-details no-border">
                              { /* <div className="text-link-date no-border">{blog.node.created}</div> */ }
                              <div className="text-link-author">by <span className="text-link-author-name">{blog.node.field_author}</span></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
              </div>

            {data.relationships.field_paragraph.map(paragraph => {
              switch(paragraph.__typename) {
                case 'paragraph__image_left_text_right_gray':
                  return (
                    <ImageLeftTextRightGray key={paragraph.id} data={paragraph} />
                  );
                  break;
              }
            })}
          </div>

          <img className="fb-pixel view-content" height="1" width="1" src="https://www.facebook.com/tr?id=199508674022010&ev=ViewContent&noscript=1"/>

        </div>
        <GeneralFooter />
      </Layout>
    )
  }
}

export default BlogsPage

export const query = graphql`
query Blogs {
  nodePage(uuid: {eq:"0f9b001e-da24-4cf3-8fb5-f0db3affe2ee"}) {
    id
    title
    field_meta_tags
    relationships {
      field_paragraph {
        __typename
        ... on paragraph__centered_title_with_subheader {
          id
          field_centered_title
          field_centered_subheading {
            value
          }
        }
        ... on paragraph__image_left_text_right_gray {
          id
          field_image_ltr_copy {
            value
          }
          field_image_ltr_link {
            title
            uri
          }
          field_image_ltr_header
          field_image_ltr_subheader
          field_image_ltr_copy {
            value
          }
          relationships {
            field_image_left_text_right_img {
              localFile {
                childImageSharp {
                  sizes(quality: 80) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  nodeBlog(field_featured:{eq:true}) {
    id
    title
    created(formatString: "MMM DD YYYY")
    field_author
    path {
      alias
    }
    relationships {
      field_large_image {
        localFile {
          childImageSharp {
            sizes(maxHeight: 500, quality: 80) {
              src
            }
          }
        }
      }
      field_paragraph {
        ... on paragraph__body_text {
          field_body_text {
            value
          }
        }
      }
    }
  }
  allNodeBlog(sort:{fields:[created], order: DESC}) {
    edges {
      node {
        id
        title
        created(formatString: "MMM DD YYYY")
        field_author
        path {
          alias
        }
        relationships {
          field_tags {
            name
          }
          field_image {
            localFile {
              childImageSharp {
                sizes(maxWidth: 500, maxHeight: 306, quality: 90) {
                  src
                }
              }
            }
          }
          field_categories {
            name
          }
        }
      }
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
