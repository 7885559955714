/* eslint-disable */
import React from 'react'
import Img from "gatsby-image";
import { Link } from "gatsby";
import sendEvent from '../helpers/sendAnalytics';

class ImageLeftTextRightGray extends React.Component {
  constructor(props) {
    super(props);

    this.target = (props.openInNewTab) ? '_blank' : '_self';
  }

  render() {
    return (
      <div className="grey-bg-image-left-text-right">
        <div className="grey-bg-image-left-text-right_wrapper">
          <div className="image-left-and-text-right blog-image-left-text-right">
            <div className="image-left-text-right-image no-bg blog-image-left-text-right-image">
              { (this.props.data.relationships.field_image_left_text_right_img && this.props.data.relationships.field_image_left_text_right_img.localFile) ?
                <img src={this.props.data.relationships.field_image_left_text_right_img.localFile.childImageSharp.sizes.src} />
              : null }
            </div>
          <div className="image-left-text-right-text blog-image-left-text-right-text">
            <div className="intro-bold-sub-title intro-bold blue-text">{this.props.data.field_image_ltr_subheader}</div>
            <div className="heading">{this.props.data.field_image_ltr_header}</div>
            <div className="section-body-text no-italics" dangerouslySetInnerHTML={{__html: this.props.data.field_image_ltr_copy.value }}></div>
            <div className="arrow-link">
              <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                      <g id="01-Home" transform="translate(-835.000000, -1171.000000)" stroke="#2B87BB" strokeWidth="2">
                          <g id="Group-5" transform="translate(836.000000, 1166.000000)">
                              <polyline id="Shape-Copy-2" points="0 6 4 10 0 14"></polyline>
                          </g>
                      </g>
                  </g>
              </svg>
              <Link
                to="/products"
                className="blue-arrow-link"
                onClick={() => sendEvent({
                  ga: {
                    category: 'Bring_Comfort_Home',
                    action: 'Theme_Filter',
                    label: 'View_Products'
                  }
                })}
              >
                {this.props.data.field_image_ltr_link.title}
              </Link>
            </div>
          </div>
         </div>

        </div>
      </div>
    )

  }
}


export default ImageLeftTextRightGray
